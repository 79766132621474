import { Injectable }              from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DataService }             from '@omnilib/services/data.service';
import { EnvironmentService }      from '@omnipas/services/environment.service';
import { ListService }             from '@omnilib/services/list.service';
import { MessageService }          from '@omnipas/services/message.service';
import { PersonService }           from '@omnipas/services/person.service';
import { RouterService }           from '@omnipas/services/router.service';
import { environment }             from '@omnienvironments/omnipas/dev/environment';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  headers:           HttpHeaders = new HttpHeaders;
  photo;
  photoResult;
  photoMiniature;
  photoAgreed:       boolean = false;
  publishPermission: boolean = false;
  saving:            boolean = false;
  webcamPhoto:       boolean = false;
  firstphoto:        boolean = false;

  mimeType:          string  = '';

  constructor( public http:        HttpClient
             , public data:        DataService
             , public environment: EnvironmentService
             , public list:        ListService
             , public message:     MessageService
             , public person:      PersonService
             , public router:      RouterService
             ) {
  }

  async read() {
    let params = { 'Resized' : 'False' };
    let result = await this.data.callapi ( 'get', 'photo', {} );

    if ( result['result']['code'] && result['result']['code'] == '0' ) {
      this.photoResult       = this.list.getValue( result['result']['resultset'], 'photo', true );
      this.publishPermission = this.list.getValue( result['result']['resultset'], 'publishPermission', true, true ) == 'True';
      this.photoAgreed       = this.photo !== null

      if ( this.environment.getEnvValue('photoMiniatureWidth') !== null && this.photo !== null ) {
        params = { 'Resized' : 'True' };
        result = await this.data.callapi ( 'get', 'photo', {} );

        if ( result['result']['code'] && result['result']['code'] == '0' ) {
          this.photoMiniature = this.list.getValue( result['result']['resultset'], 'photo', true );
        }
      }
    }
  }

  async save( permissions = false ) {
    this.router.toggleshowwait( true );
    this.saving = true;

    let resultpage = permissions ? 'photopermissions' : 'photo';

    if ( this.photoResult != undefined && this.photoResult != '' && !this.list.emptyList( this.router.persondata ) ) {

      if ( this.photoResult.length < 10000 ) {
        this.message.setErrorMessage ( resultpage + '.result.failure', false, true );
        return;
      }

      this.firstphoto = this.list.getValue( this.router.persondata, 'photo', true ) == '';

      let params = {};

      if ( this.environment.getEnvValue('publishPermission') ) {
        params['publishPermission'] = this.publishPermission ? 1 : 0;
      }

      if ( this.environment.getEnvValue('photoMiniatureWidth') != null ) {
        params['photo']         = this.photoMiniature.replace('data:image/jpeg;base64,', '');
        params['originalPhoto'] = this.photoResult.replace('data:image/jpeg;base64,', '');
      } else {
        params['photo']         = this.photoResult.replace('data:image/jpeg;base64,', '');
      }

      let result = await this.data.callapi( this.firstphoto ? 'post' : 'put', 'photo', params );

      if ( result['result']['code'] && result['result']['code'] == '0' ) {
        if ( await this.person.getPersonData() ) {
          this.message.setSuccessMessage ( resultpage + '.result.success', false, true );
        } else {
          this.message.setErrorMessage ( resultpage + 'result.failure', false, true );
        }
      } else {
        this.message.setErrorMessage ( resultpage + '.result.failure', false, true );
      }
    }

    this.saving = false;

    if ( permissions ) {
      this.router.goTo( 'photopermissions' );
      this.router.toggleshowwait(false);
    } else {
      this.router.goTo('result');
    }
  }

  togglePhotoAgreed() {
    this.photoAgreed = !this.photoAgreed;
  }

  togglePublishPermission() {
    this.publishPermission = !this.publishPermission;
  }

  async savetofile() {
    this.router.toggleshowwait( true );

    this.saving = true;

    if ( this.photoResult != undefined && this.photoResult != '' && !this.list.emptyList( this.router.persondata ) ) {

      if ( this.photoResult.length < 10000 ) {
        this.message.setErrorMessage ( 'photo.result.failure', false, true );
        return;
      }

      let jsonParams = { 'file'    : this.list.getValue( this.router.persondata, 'cardholderProfile', true )+'/'+this.list.getValue( this.router.persondata, 'personNumber', true )
                       , 'photo'   : this.photoResult.replace('data:image/jpeg;base64,', '')
                       , 'what'    : 'savephoto'
                       }

      let params = jsonParams;

      let url = environment.production ? environment.apiurl : 'wsdl';

      try {
        this.headers.set('Access-Control-Allow-Origin', '*');
        await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
        this.message.setSuccessMessage ( 'photo.result.success', false, true );

        this.router.persondata['photo']             = { 'Value' : await this.getfile() };

      } catch(e) {
        this.message.setErrorMessage ( 'photo.result.failure', false, true );
      }

      this.saving = false;
      this.router.goTo('result');
    }
  }

  async getfile() {
    let jsonParams = { 'file'    : this.list.getValue( this.router.persondata, 'cardholderProfile', true )+'/'+this.list.getValue( this.router.persondata, 'personNumber', true )
                     , 'what'    : 'getphoto'
                     }

    let params = jsonParams;

    let url = environment.production ? environment.apiurl : 'wsdl';

    try {
      this.headers.set('Access-Control-Allow-Origin', '*');
      let result = await this.http.post( url, { content: params } , {  headers: this.headers, responseType: 'text' } ).toPromise();
      return result;
    } catch(e) {
      return '';
    }
  }
}
