<div class="w3-display-topright showontop" *ngIf="!router.digitalcardinnewtab">
  <fa-icon id="icon-close" [icon]="icons.fatimes" class="w3-hover-shadow close" (click)="close()" title="{{ 'buttons.close' | translate: router.transparams }}"></fa-icon>
  &nbsp;
</div>
<div *ngIf="initialized && message.error">
  <omnipas-pageheader></omnipas-pageheader>
</div>
<div *ngIf="initialized && !message.error" class="digitalcardcontainer w3-animate-zoom w3-white" [class]="getorientation()">
  <div
    id      = "digitalcard"
    title   = "{{ 'welcome.cardinfo' | translate: router.transparams }}"
    class   = "w3-theme-l4"
    [class] = "getClass()"
  >
    <div id="logocontainer" class="">
      <img class="logo" src="./assets/images/logo2.png" alt="logo2.png" title="logo">
    </div>
    <div id="photocontainer" class="">
      <img class="photo" alt="photo" src="data:image/jpg;base64,{{list.getValue( this.router.persondata, 'photo', true )}}">
    </div>
    <div id="datacontainer" class="">
      <div class="name">
        <div class="title"       >{{ list.getValue( this.router.persondata, 'title', true ) }}</div>
        <div class="initials"    >{{ list.getValue( this.router.persondata, 'initials'   , true ) }}</div>
        <div class="firstname"   >{{ list.getValue( this.router.persondata, 'firstName'  , true ) }}</div>
        <div class="middlename"  >{{ list.getValue( this.router.persondata, 'middleName' , true ) }}</div>
        <div class="lastname"    >{{ list.getValue( this.router.persondata, 'lastName'   , true ) }}</div>
        <div class="nameoncard"  >{{ list.getValue( this.router.persondata, 'nameOnCard' , true ) }}</div>
      </div>
      <div class="group"         >{{ list.getValue( this.router.persondata, 'cardholderProfile', true ) }}</div>
      <div class="departement"   >{{ list.getValue( this.router.persondata, 'DepartmentName'   , true ) }}</div>
      <div class="personnumber"  >{{ list.getValue( this.router.persondata, 'personNumber'     , true ) }}</div>
      <div class="profilenumber" >{{ list.getValue( this.router.persondata, 'profileNumber'    , true ) }}</div>
      <div class="cardnumber"    >{{ list.getValue( this.router.persondata, 'cardNumber'       , true ) }}</div>
      <div class="startdate"     >{{ startdate }}</div>
      <div class="enddate"       >{{ expdate }}</div>
    </div>
    <div id="qrcodecontainer" class="">
      <!--
      <qrcode-svg class="qrcode" value="{{ list.getValue( this.router.persondata, 'cardNumber', true ) }}" margin="0"></qrcode-svg>
      -->
    </div>
    <div id="barcodecontainer" class="">
      <omnicard-barcode class="barcode" code="{{list.getValue( this.router.persondata, 'cardNumber', true )}}" [displayvalue]="true"></omnicard-barcode>
    </div>
  </div>
