import { Injectable } from '@angular/core';
import { MessageService } from '@omnipas/services/message.service';

@Injectable({
  providedIn: 'root'
})
export class PageHeaderService {

  pageTitle: string  = '';
  pageText:  string  = '';
  showName:  string  = '';

  constructor( public message: MessageService ) {
  }

  setPageHeader ( $title = '', $text = '' ) {
    this.pageTitle = $title;

    if ( $text ) {
      this.message.setTitle( $text );
    }
  }
}
