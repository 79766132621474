import { Component, OnInit, OnDestroy } from '@angular/core';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { ListService }        from '@omnilib/services/list.service';
import { LoadingService }     from '@omnipas/services/loading.service';
import { MessageService }     from '@omnipas/services/message.service';
import { PageHeaderService }  from '@omnipas/services/pageheader.service';
import { PhotoService }       from '@omnipas/services/photo.service';
import { RouterService }      from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-photoresult',
  templateUrl: './photoresult.component.html'
})
export class PhotoResultComponent implements OnInit, OnDestroy {

  initialized:     boolean = false;
  deliveryaddress: boolean = false;

  constructor ( public environment: EnvironmentService
              , public list:        ListService
              , public loading:     LoadingService
              , public message:     MessageService
              , public pageheader:  PageHeaderService
              , public photo:       PhotoService
              , public router:      RouterService
              ) {
  }

  async ngOnInit() {
    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.list.emptyList( this.router.persondata ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader( 'pagetitle.photoresult' );
    }

    this.deliveryaddress  = this.message.success
                        && this.list.getValue( this.router.persondata, 'cardStatus', true ) == 0
                        && this.environment.getEnvValue('deliveryaddress2')
                        && ( this.list.getValue( this.router.persondata, 'cardholderProfile', true ).toLowerCase() == 'student' );
    this.router.firstPhoto = this.photo.firstphoto;
    this.initialized     = true;
    this.router.toggleshowwait( false );
  }

  logout() {
    this.router.logout();
  }

  back() {
    this.router.goTo('photo');
  }

  continue() {
    this.router.goTo('deliveryaddress2');
  }

  ngOnDestroy() {
    return;
  }

}
