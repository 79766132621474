import { Component, OnInit, HostListener, AfterViewInit, OnDestroy } from '@angular/core';
import { IconsService }    from '@omnipas/services/icons.service';
import { LanguageService } from '@omnipas/services/language.service';
import { ListService }     from '@omnilib/services/list.service';
import { PersonService }   from '@omnipas/services/person.service';
import { PhotoService }    from '@omnipas/services/photo.service';
import { RouterService }   from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit, OnDestroy, AfterViewInit{

  constructor ( public icons:    IconsService
              , public language: LanguageService
              , public list:     ListService
              , public person:   PersonService
              , public photo:    PhotoService
              , public router:   RouterService
              ) {
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    let menu       = document.getElementById('menu');
    let menutoggle = document.getElementById('menutoggle');
    let logo       = document.getElementById('logo');
    let logoicon   = document.getElementById('logoicon');

    let showMenu   = window.innerWidth < 450;
    let showLogo   = window.innerWidth < 300;

    menu.classList.remove( showMenu ? 'show' : 'hide' );
    menu.classList.add( showMenu ? 'hide' : 'show' );
    menutoggle.classList.remove( showMenu ? 'hide' : 'show' );
    menutoggle.classList.add( showMenu ? 'show' : 'hide' );

    logo.classList.remove( showLogo ? 'show' : 'hide' );
    logo.classList.add( showLogo ? 'hide' : 'show' );
    logoicon.classList.remove( showLogo ? 'hide' : 'show' );
    logoicon.classList.add( showLogo ? 'show' : 'hide' );
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.getScreenSize();
  }

  logout() {
    this.router.logout();
  }

  async goHome() {
    this.router.toggleshowwait( true );

    this.photo.photoResult = '';
    if ( await this.person.getPersonData() ) {
      this.router.goHome();
    } else {
      this.router.goToLogin();
    }
    this.router.toggleshowwait( false );
  }

  showAbout() {
    this.router.showabout = true;
  }

  toggleMenu() {
    let menu = document.getElementById('menu');
    let logo = document.getElementById('logo');
    let logoicon = document.getElementById('logoicon');

    let showMenu = menu.classList.contains('show');

    menu.classList.remove( showMenu ? 'show' : 'hide' );
    menu.classList.add( showMenu ? 'hide' : 'show' );
    logo.classList.remove( showMenu ? 'hide' : 'show' );
    logo.classList.add( showMenu ? 'show' : 'hide' );
    logoicon.classList.remove( showMenu ? 'show' : 'hide' );
    logoicon.classList.add( showMenu ? 'hide' : 'show' );
  }

  toggleAbout() {
    return this.router.transparams.customer_shortname == '' ? 'disabled' : '';
  }

  ngOnDestroy() {
    return;
  }
}
