import { Component, OnInit, OnDestroy } from '@angular/core';
import { MessageService }    from '@omnipas/services/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { RouterService }     from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-fatalerror',
  templateUrl: './fatalerror.component.html'
})
export class FatalErrorComponent implements OnInit, OnDestroy {

  initialized: boolean = false;

  constructor ( public message: MessageService
              , public pageheader: PageHeaderService
              , public router: RouterService
              ) {
  }

  ngOnInit() {
    this.router.toggleshowwait( true );
    this.initialized = false;
    this.pageheader.setPageHeader( 'pagetitle.fatalerror' );
    this.initialized = true;
    this.router.toggleshowwait( false );
  }

  ngOnDestroy() {
    return;
  }

}
