import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';

import { NgModule }                         from '@angular/core';
import { FormsModule }                      from '@angular/forms';
import { HttpClient, HttpClientModule }     from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { HttpLoaderFactory }                from './core/services/http.loader.factory';
import { OAuthModule, OAuthModuleConfig }   from 'angular-oauth2-oidc';
import { ImageCropperModule }               from 'ngx-image-cropper';
// import { QRCodeSVGModule }                  from 'ngx-qrcode-svg';
import { FontAwesomeModule }                from '@fortawesome/angular-fontawesome';

import { AppComponent }                from './app.component';
import { AppRoutingModule }            from './app-routing.module';
import { AboutComponent }              from '@omnipas/modules/about/about.component';
import { ActivateCardComponent }       from '@omnipas/modules/activatecard/activatecard.component';
// import { ADFSLoginComponent }          from '@omnipas/modules/adfslogin/adfslogin.component';
import { BarcodeComponent }            from '@omnilib/modules/barcode/barcode.component';
import { BlockCardComponent }          from '@omnipas/modules/blockcard/blockcard.component';
import { BreadcrumbComponent }         from '@omnipas/modules/breadcrumb/breadcrumb.component';
import { CardInfoComponent }           from '@omnipas/modules/cardinfo/cardinfo.component';
import { CardPreviewComponent }        from '@omnipas/modules/cardpreview/cardpreview.component';
import { ChargingCardComponent }       from '@omnipas/modules/chargingcard/chargingcard.component';
import { CardStatusComponent }         from '@omnipas/modules/cardstatus/cardstatus.component';
import { DeblockCardComponent }        from '@omnipas/modules/deblockcard/deblockcard.component';
import { DeliveryAddressComponent }    from '@omnipas/modules/deliveryaddress/deliveryaddress.component';
import { DeliveryAddress2Component }   from '@omnipas/modules/deliveryaddress2/deliveryaddress2.component';
import { DigitalCardComponent }        from '@omnipas/modules/digitalcard/digitalcard.component';
import { FatalErrorComponent }         from '@omnipas/modules/fatalerror/fatalerror.component';
import { FontToggleComponent }         from '@omnipas/modules/fonttoggle/fonttoggle.component';
import { FooterComponent }             from '@omnipas/modules/footer/footer.component';
import { HeaderComponent }             from '@omnipas/modules/header/header.component';
import { LanguageToggleComponent }     from '@omnipas/modules/languagetoggle/languagetoggle.component';
import { LicensePlateComponent }       from '@omnipas/modules/licenseplate/licenseplate.component';
import { LoadingComponent }            from '@omnipas/modules/loading/loading.component';
import { LoginComponent }              from '@omnipas/modules/login/login.component';
import { LoginCheckComponent }         from '@omnipas/modules/logincheck/logincheck.component';
import { LogoutComponent }             from '@omnipas/modules/logout/logout.component';
import { MaintainanceComponent }       from '@omnipas/modules/maintainance/maintainance.component';
import { MessageComponent }            from '@omnipas/modules/message/message.component';
import { MoreinfoComponent }           from '@omnipas/modules/moreinfo/moreinfo.component';
//import { NameOnCardComponent }         from '@omnipas/modules/nameoncard/nameoncard.component';
import { PageHeaderComponent }         from '@omnipas/modules/pageheader/pageheader.component';
import { PaymentComponent }            from '@omnipas/modules/payment/payment.component';
import { PermissionsInfoComponent }    from '@omnipas/modules/permissionsinfo/permissionsinfo.component';
import { PhotoEditComponent }          from '@omnipas/modules/photoedit/photoedit.component';
import { PhotoPermissionsComponent }   from '@omnipas/modules/photopermissions/photopermissions.component';
import { PhotoRequirementsComponent }  from '@omnipas/modules/photorequirements/photorequirements.component';
import { PhotoResultComponent }        from '@omnipas/modules/photoresult/photoresult.component';
import { RequestCardComponent }        from '@omnipas/modules/requestcard/requestcard.component';
import { RequestPasswordComponent }    from '@omnipas/modules/requestpassword/requestpassword.component';
import { ResetPasswordComponent }      from '@omnipas/modules/resetpassword/resetpassword.component';
//import { SamlComponent }               from '@omnipas/modules/saml/saml.component';
import { SurfLoginComponent }          from '@omnipas/modules/surflogin/surflogin.component';
import { ShowwaitComponent }           from '@omnipas/modules/showwait/showwait.component';
import { WebcamComponent }             from '@omnipas/modules/webcam/webcam.component';
import { WelcomeComponent }            from '@omnipas/modules/welcome/welcome.component';

import { CustomTitleCasePipe
       , CustomInitCapPipe }           from '@omnipas/pipes/pipes';

import { environment }                 from '@omnienvironments/omnipas/dev/environment';

const authModuleConfig: OAuthModuleConfig = {
  // Inject "Authorization: Bearer ..." header for these APIs:
  resourceServer: {
    allowedUrls: ['https://localhost:8080'],
     sendAccessToken: true,
  }
};

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    ActivateCardComponent,
    //ADFSLoginComponent,
    BarcodeComponent,
    BlockCardComponent,
    BreadcrumbComponent,
    CardInfoComponent,
    CardPreviewComponent,
    CardStatusComponent,
    ChargingCardComponent,
    DeblockCardComponent,
    DeliveryAddressComponent,
    DeliveryAddress2Component,
    DigitalCardComponent,
    FatalErrorComponent,
    FontToggleComponent,
    FooterComponent,
    HeaderComponent,
    LanguageToggleComponent,
    LicensePlateComponent,
    LoadingComponent,
    LoginComponent,
    LoginCheckComponent,
    LogoutComponent,
    MaintainanceComponent,
    MessageComponent,
    MoreinfoComponent,
    //NameOnCardComponent,
    PageHeaderComponent,
    PaymentComponent,
    PermissionsInfoComponent,
    PhotoEditComponent,
    PhotoPermissionsComponent,
    PhotoResultComponent,
    PhotoRequirementsComponent,
    //QRCodeComponent,
    RequestCardComponent,
    RequestPasswordComponent,
    ResetPasswordComponent,
    //SamlComponent,
    SurfLoginComponent,
    ShowwaitComponent,
    WebcamComponent,
    WelcomeComponent,
    CustomTitleCasePipe,
    CustomInitCapPipe
  ],
  imports: [
    BrowserModule,
    HammerModule,
    // QRCodeSVGModule,
    FormsModule,
    FontAwesomeModule,
    AppRoutingModule,
    HttpClientModule,
    OAuthModule.forRoot(authModuleConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    ImageCropperModule,
  ],
  providers: [
    { provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerGestureConfig
    }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
