import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  animate:   boolean = false;
  error:     boolean = false;
  info:      boolean = false;
  success:   boolean = false;
  question:  boolean = false;
  title:     boolean = false;
  warning:   boolean = false;
  message:   string  = '';

  showClose: boolean = false;

  lastTitle: string = '';

  constructor() {
  }

  initialize() {
    this.clearMessage();
  }


  clearMessage( $animate = false ) {
    this.setTitle( this.lastTitle, $animate );
  }

  setTitle( $message, $showClose = false, $animate = false ) {
    this.title     = true;
    this.success   = false;
    this.error     = false;
    this.info      = false;
    this.warning   = false;
    this.question  = false;
    this.message   = $message;
    this.lastTitle = $message;
    this.animate   = $animate;
    this.showClose = $showClose;
  }

  setSuccessMessage( $message, $showClose = false, $animate = false ) {
    this.title     = false;
    this.success   = true;
    this.error     = false;
    this.info      = false;
    this.warning   = false;
    this.question  = false;
    this.message   = $message;
    this.animate   = $animate;
    this.showClose = $showClose;
  }

  setErrorMessage( $message,  $showClose = false, $animate = false ) {
    this.title     = false;
    this.success   = false;
    this.error     = true;
    this.info      = false;
    this.warning   = false;
    this.question  = false;
    this.message   = $message;
    this.animate   = $animate;
    this.showClose = $showClose;
  }

  setInfoMessage( $message, $showClose = false, $animate = false ) {
    this.title     = false;
    this.success   = false;
    this.error     = false;
    this.info      = true;
    this.warning   = false;
    this.question  = false;
    this.message   = $message;
    this.animate   = $animate;
    this.showClose = $showClose;
  }

  setWarningMessage( $message, $showClose = false, $animate = false ) {
    this.title     = false;
    this.error     = false;
    this.success   = false;
    this.info      = false;
    this.warning   = true;
    this.question  = false;
    this.message   = $message;
    this.animate   = $animate;
    this.showClose = $showClose;
  }

  setQuestion( $message, $showClose = false, $animate = false ) {
    this.title     = false;
    this.success   = false;
    this.error     = false;
    this.info      = false;
    this.warning   = false;
    this.question  = true;
    this.message   = $message;
    this.animate   = $animate;
    this.showClose = $showClose;
  }

  setOtherMessage ( $message, $showClose = false, $animate = false ) {
    this.title     = false;
    this.success   = false;
    this.error     = false;
    this.info      = false;
    this.warning   = false;
    this.question  = false;
    this.message   = $message;
    this.animate   = $animate;
    this.showClose = $showClose;
  }

}
