export const environment = {
  production: true,
  apiurl: 'https://soap2.omnicms3.nl:9001/api/v1/',
  mock: false,
  config: {
    product: "Yuverta Student card",
    customer_fullname: "Yuverta College",
    customer_shortname: "Yuverta",
    url: "https://yuverta.sharepoint.com/sites/wetenregelen-std/SitePages/Student-card.aspx",
    phone: "",
    email: "",
    MFAemail: false,
    MFAsms: false,
    logintype : "surf",
    autologin: false,
    username: "",
    password: "",
    samlConfig: {},
    surfConfig: { issuer: "https://connect.surfconext.nl"
                , clientId : "yuverta.mijnomnipas.nl"
                , redirectUri: "https://yuverta.mijnomnipas.nl/site/surflogin"
                , silentRefreshRedirectUri: "https://yuverta.mijnomnipas.nl/site/silent-refresh.html"
                , logoutUrl: "https://engine.surfconext.nl/logout"
                , scope: "openid profile email"
                , loginItem: "uids"
                , oidc: true
                , skipPage: true
                , secret: "6438CWiKmAlfWFHaR5rr"
                },
    adfsConfig: {},
    ingenico: {
      url: "https://secure.ogone.com/ncol/prod/orderstandard.asp",
      orderid: "",
      pspid: "YuvertaSchoolpasPROD",
      sha_in_key: "*FYhC70BTPjcC]f0{CN",
      sha_out_key: ")yLm#EYIqi23Yo6HDY%",
      currency: "EUR",
      bgcolor: "#F3F3F3",
      txtcolor: "#004C93",
      tblbgcolor: "#FFFFFF",
      tbltxtcolor: "#666666",
      buttonbgcolor: "#004C93",
      buttontxtcolor: "#FFFFFF",
      fonttype: "Verdana",
      pm: "iDEAL",
      homeurl: "https://yuverta.mijnomnipas.nl/site/payment",
      accepturl: "https://yuverta.mijnomnipas.nl/site/payment/success",
      declineurl: "https://yuverta.mijnomnipas.nl/site/payment/decline",
      exceptionurl: "https://yuverta.mijnomnipas.nl/site/payment/fail",
      cancelurl: "https://yuverta.mijnomnipas.nl/site/payment/cancel"
    },
//    cardprices: { "Student": 1500, "Employee": 1000, "External employee": 1000, "default": 1250 },
    cardprices: { "Student": 750, "Employee": 0, "External employee": 0, "default": 500 },
    languages: [
      {
        id: 1,
        LanguageCode: "nl",
        LanguageName: "Nederlands",
        Card: "pas"
      },
      {
        id: 2,
        LanguageCode: "en",
        LanguageName: "English",
        Card: "pass"
      },
    ],
    nameoncardformats: [
      { id: 1, format: "<<Initials>> <<MiddleName>> <<LastName>>" },
      { id: 2, format: "<<FirstName>> <<MiddleName>> <<LastName>>" },
      { id: 3, format: "<<LastName>>, <<FirstName>> <<MiddleName>>" }
    ],
    savephotoonserver: false,
    changepublishpermission: false,
    chargingcard: false,
    digitalcard: false,
    uploadphoto: true,
    licenseplate: false,
    nameoncard: false,
    cardpreview: true,
    cardaslink: true,
    cardresult: false,
    cardactivate: true,
    cardblock: true,
    carddeblock: true,
    cardrequest: true,
    cardhist: false,
    logoutbutton: false,
    requestnewpassword: true,
    publishPermission: true,
    scrollButtons: true,
    breadcrumbs: false,
    versioninfooter: true,
    photoResizeToWidth: 480,
    photoMiniatureWidth: 240,
    togglefont: false,
    photorequirements: false,
    firstnameonwelcome: true,
    photoAgreedNotOnFirstPage: true,
    deliveryaddress: false,
    deliveryaddress2: true,
    langimage : false,
    maintainance: false,
    timeout: 180,
    student: {

      webcam: true,

      uploadphoto: true,

    },

    employee: {

      webcam: true,

      uploadphoto: true,

    }
  },
  ssl: true
};
