<div *ngIf="initialized" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <form action="" method="" *ngIf="!message.error">
    <div class="w3-margin">
      <label for="code">{{ 'labels.code' | translate: router.transparams }}</label>
      <input
        id            = "passcode"
        type          = "password"
        name          = "passcode"
        class         = "w3-input w3-border w3-input-theme password w3-round"
        placeholder   = "{{ 'labels.code' | translate: router.transparams }}"
        autocomplete  = "off"
        [(ngModel)]   = "code"
        required      = "required"
        (keyup.enter) = "submit()"
        tabindex      = "1"
      >
      <fa-icon
        id          = "togglePasscode"
        name        = "togglePasscode"
        [icon]      = "eye"
        (mouseover) = "togglePasscode( true )"
        (mouseout)  = "togglePasscode( false )"
        title       = "{{ 'login.showhide' | translate: router.transparams }}"
      ></fa-icon>
    </div>
    <div class="w3-margin">
      <input
        type        = "reset"
        id          = "reset"
        name        = "reset"
        class       = "w3-button w3-border w3-button-theme w3-margin-right pageButton w3-round"
        title       = "{{ 'buttons.reset' | translate: router.transparams }}"
        value       = "{{ 'buttons.reset' | translate: router.transparams }}"
        (click)     = "reset()"
        [disabled]  = "enableReset() ? '' : 'disabled'"
        tabindex    = "3"
      >
      <input
        type        = "button"
        id          = "submit"
        name        = "submit"
        class       = "w3-button w3-border w3-button-theme pageButton w3-round"
        title       = "{{ 'buttons.next' | translate: router.transparams }}"
        value       = "{{ 'buttons.next' | translate: router.transparams }}"
        (click)     = "submit()"
        [disabled]  = "enableSubmit() ? '' : 'disabled'"
        tabindex    = "4"
      >
    </div>
  </form>
  <div *ngIf="message.error" class="w3-margin">
    <input
      type        = "button"
      id          = "ok"
      name        = "ok"
      class       = "w3-button w3-border w3-button-theme pageButton w3-round"
      title       = "{{ 'buttons.ok' | translate: router.transparams }}"
      value       = "{{ 'buttons.ok' | translate: router.transparams }}"
      (click)     = "router.goToLogin()"
    >
  </div>
</div>
