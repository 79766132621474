import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { DataService }        from '@omnilib/services/data.service';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { IconsService }       from '@omnipas/services/icons.service';
import { LanguageService }    from '@omnipas/services/language.service';
import { ListService }        from '@omnilib/services/list.service';
import { LoginService }       from '@omnipas/services/login.service';
import { LoadingService }     from '@omnipas/services/loading.service';
import { RouterService }      from '@omnipas/services/router.service';

@Component({
  selector: 'omnicard-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  initialized:   boolean = false;
  showUpArrow:   boolean = false;
  showDownArrow: boolean = false;
  resetpassword: boolean = false;

  constructor ( public data:           DataService
              , public environment:    EnvironmentService
              , public icons:          IconsService
              , public list:           ListService
              , public login:          LoginService
              , public loading:        LoadingService
              , public router:         RouterService
              ) {
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    let body        = document.getElementById('body');
    let main        = document.getElementById('main');
    let toosmall    = document.getElementById('toosmall');

    this.router.toosmall = window.innerWidth < 280;

    if ( window.innerWidth < 280 ) {
      body.classList.remove("wide");
      body.classList.remove("small");
      body.classList.add("toosmall");
    } else if ( window.innerWidth < 650 ) {
      body.classList.remove("toosmall");
      body.classList.remove("wide");
      body.classList.add("small");
    } else {
      body.classList.remove("toosmall");
      body.classList.remove("small");
      body.classList.add("wide");
    }

    if ( !this.router.showdigitalcard ) {
      this.router.mainwidth  = main.offsetWidth;
      this.router.mainheight = main.offsetHeight;

      this.showScrollArrows();
    }
  }

  @HostListener('window:scroll', ['$event'])
  showScrollArrows() {
    this.showUpArrow = document.body.scrollTop > 20 || document.documentElement.scrollTop > 20;
    this.showDownArrow = document.body.scrollTop < document.body.scrollHeight - document.body.clientHeight || document.documentElement.scrollTop < document.documentElement.scrollHeight - document.documentElement.clientHeight;
  }

  @HostListener('window:beforeunload', ['$event'])
  cleanUp($event) {
  }

  async ngOnInit(){
    await this.initialize();

    if ( window.location.pathname.includes( 'resetpassword' ) ) {
      let baseurl = window.location.origin;

      let a = window.location.pathname.split('/');

      for ( let i in a ) {
        if ( a[i] == 'resetpassword') {
          this.login.resetpwtoken = a[+i+1];
          break;
        } else {
          baseurl += '/' + a[i];
        }
      }

      this.resetpassword   = true;
    }
  }

  async initialize() {
    this.router.toggleshowwait(true);

    if ( this.list.emptyList( this.router.persondata ) ) {
      await this.loading.load();
    }

    this.getScreenSize();

    this.initialized = true;
  }

  scrollToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    this.showScrollArrows();
  }

  scrollToBottom() {
    document.body.scrollTop = document.body.scrollHeight;
    document.documentElement.scrollTop = document.documentElement.scrollHeight;
    this.showScrollArrows();
  }

  ngOnDestroy(){
    if ( !this.router.paying ) {
      this.clearStorage();
    }
  }

  clearStorage() {
    this.data.clearjwt();
  }
}
