import { Injectable }              from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class JWTService {
  set( jwtstring ) {
    sessionStorage.setItem( 'jwt', jwtstring );
  }

  get() {
    return sessionStorage.getItem('jwt') || '';
  }

  remove() {
    sessionStorage.removeItem('jwt');
  }

  clear() {
    sessionStorage.clear();
  }
}
