import { Component, OnInit, OnDestroy } from '@angular/core';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { IconsService }       from '@omnipas/services/icons.service';
import { ListService }        from '@omnilib/services/list.service';
import { RouterService }      from '@omnipas/services/router.service';
import { format }             from 'date-fns';

@Component({
  selector: 'omnipas-about',
  templateUrl: './about.component.html'
})
export class AboutComponent implements OnInit, OnDestroy {
  year;

  constructor ( public environment: EnvironmentService
              , public icons:       IconsService
              , public list:        ListService
              , public router:      RouterService
              ) {
  }

  ngOnInit() {
    this.year = format(new Date(),'yyyy');
  }

  hideAbout() {
    this.router.showabout = false;
  }

  showMoreinfo() {
    this.router.showabout = false;
    this.router.showmoreinfo = true;
  }

  showDisclaimer() {
  }

  showTerms() {
  }

  ngOnDestroy() {
    return;
  }
}
