import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { ListService }       from '@omnilib/services/list.service';
import { LoadingService }    from '@omnipas/services/loading.service';
import { MessageService }    from '@omnipas/services/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { PhotoService }      from '@omnipas/services/photo.service';
import { RouterService }     from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-webcam',
  templateUrl: './webcam.component.html'
})
export class WebcamComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor ( public list:       ListService
              , public loading:    LoadingService
              , public message:    MessageService
              , public pageheader: PageHeaderService
              , public photo:      PhotoService
              , public router:     RouterService
              ) {
  }

  @ViewChild('camera')   video;
  @ViewChild('myCanvas') canvas;

  initialized: boolean = false;
  phototaken:  boolean = false;
  camstarted:  boolean = false;

  public  ctx;

  ngOnInit() {
    this.router.toggleshowwait( true );
    this.initialized = false;
    this.phototaken  = false;

    if ( this.list.emptyList( this.router.persondata ) ) {
      console.log ( 'emptylist' );
      this.router.logout();
    } else {
      this.pageheader.setPageHeader( 'pagetitle.webcam', 'pagetext.webcamdetection' );
    }

    this.initialized = true;
    this.router.toggleshowwait( false );
  }

  async ngAfterViewInit() {
    await this.startCamera();
  }

  async startCamera() {
    const _video = this.video.nativeElement;
    let   _camfound = false;

    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      await navigator.mediaDevices.getUserMedia({ video: true })
        .then(function (stream) {
          _camfound = true;
          _video.srcObject = stream;
        })
        .catch(function (error) {
        });
    }

    if ( _camfound ) {
      this.camstarted = true;
      this.message.setTitle( 'pagetext.webcam1' );
    } else {
      this.message.setErrorMessage( 'pagetext.webcamnotfound' );
    }
  }

  stopCamera() {
    if ( this.camstarted ) {
      const _video = this.video.nativeElement;

      let stream = _video.srcObject;
      let tracks = stream.getTracks();

      for (let i = 0; i < tracks.length; i++) {
        let track = tracks[i];
        track.stop();
      }

      _video.srcObject = null;
      this.camstarted = false;
    }
  }

  takePicture() {
    const _video  = this.video.nativeElement;
    const _canvas = this.canvas.nativeElement;

    _canvas.width  = _video.offsetWidth;
    _canvas.height = _video.offsetHeight;

    this.ctx = _canvas.getContext('2d');
    this.ctx.translate(_canvas.width, 0);
    this.ctx.scale(-1, 1);

    this.ctx.drawImage(_video, 0, 0, _canvas.width, _canvas.height);

    this.pageheader.setPageHeader( 'pagetitle.webcam', 'pagetext.webcam2' );

    this.stopCamera();

    this.phototaken = true;

  }

  back() {
    this.router.goBack();
    this.stopCamera();
  }

  reset() {
    this.pageheader.setPageHeader( 'pagetitle.webcam', 'pagetext.webcam1' );
    this.phototaken = false;
    this.startCamera();
  }

  save() {
    this.router.toggleshowwait( true );
    const _canvas = this.canvas.nativeElement;
    this.photo.photo = _canvas.toDataURL("image/jpg");
    this.photo.webcamPhoto = true;
    this.router.goTo('photo');
    this.stopCamera();
  }

  ngOnDestroy() {
  }

}
