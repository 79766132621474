import { Component, OnInit, OnDestroy } from '@angular/core';
import { LoadingService }    from '@omnipas/services/loading.service';
import { MessageService }    from '@omnipas/services/message.service';
import { PageHeaderService } from '@omnipas/services/pageheader.service';
import { RouterService }     from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-loading',
  templateUrl: './loading.component.html'
})
export class LoadingComponent implements OnInit, OnDestroy{

  initialized: boolean = false;

  constructor ( public loading:    LoadingService
              , public message:    MessageService
              , public pageheader: PageHeaderService
              , public router:     RouterService
              ) {
  }

  ngOnInit() {
    this.initialize();
  }

  initialize() {
    this.initialized = false;
    this.pageheader.setPageHeader( 'pagetitle.loading', 'pagetext.loading' );
    this.initialized = true;
  }

  ngOnDestroy() {
    return;
  }

}
