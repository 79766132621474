import { Component, OnInit, OnDestroy } from '@angular/core';
import { CardService }            from '@omnipas/services/card.service';
import { DataService }            from '@omnilib/services/data.service';
import { DeliveryAddressService } from '@omnipas/services/deliveryaddress.service';
import { EnvironmentService }     from '@omnipas/services/environment.service';
import { ListService }            from '@omnilib/services/list.service';
import { LoadingService }         from '@omnipas/services/loading.service';
import { MessageService }         from '@omnipas/services/message.service';
import { PageHeaderService }      from '@omnipas/services/pageheader.service';
import { RouterService }          from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-deliveryaddress2',
  templateUrl: './deliveryaddress2.component.html'
})
export class DeliveryAddress2Component implements OnInit, OnDestroy {

  initialized:       boolean = false;

  postalcode:        string = '';
  housenumber:       string = '';
  housenumbersuffix: string = '';
  street:            string = '';
  city:              string = '';

  change:            boolean = false;
  addressfound:      boolean = false;
  saved:             boolean = false;
  searching:         boolean = false;
  savingDone:        boolean = false;

  photovalidation:        boolean = false;
  photoapproved:          boolean = false;

  constructor ( public card:            CardService
              , public data:            DataService
              , public deliveryaddress: DeliveryAddressService
              , public environment:     EnvironmentService
              , public list:            ListService
              , public loading:         LoadingService
              , public message:         MessageService
              , public pageheader:      PageHeaderService
              , public router:          RouterService
              ) {
  }

  async ngOnInit() {

    this.router.toggleshowwait( true );

    this.initialized = false;

    if ( this.list.emptyList( this.router.persondata ) ) {
      this.router.logout();
    } else {
      this.pageheader.setPageHeader( 'pagetitle.deliveryaddress', 'pagetext.deliveryaddress' );
    }

    this.initialized = true;

    this.router.toggleshowwait( false );
  }

  logout() {
    this.router.logout();
  }

  toggleChange() {
    this.pageheader.setPageHeader( 'pagetitle.deliveryaddress', 'deliveryaddress.line5' );
    this.change = true;
  }

  formatZipCode() {
    // if ( this.postalcode.length == 4 ) {
    //   this.postalcode = this.postalcode + ' ';
    //   return;
    // }

    // if ( this.postalcode.length != 7 || this.housenumber.length == 0  ) {
    //   this.street       = '';
    //   this.city         = '';
    //   this.addressfound = false;
    //   return;
    // }
    return;
    //this.searchAddress( disctrictcode, lettercombination, this.housenumber );
  }

  async searchAddress() {
    if ( this.postalcode.length != 7 || this.housenumber.length == 0  ) {
      this.street       = '';
      this.city         = '';
      this.addressfound = false;
      this.searching    = false;
      return;
    }

    this.router.toggleshowwait( true );

    this.searching = true;

    let result = await this.data.getpc( this.postalcode.replace(' ', ''), this.housenumber );

    if ( result != null ) {
      this.street       = this.list.getValue( result, 'street', true );
      this.city         = this.list.getValue ( result, 'city', true );
      this.addressfound = true;
    } else  {
      this.addressfound = false;
      return;
    }

    this.searching = false;
    this.router.toggleshowwait( false );
  }

  enableSubmit() {
    return this.street == '' || this.housenumber == '' || this.postalcode == '' || this.city == '' ? 'disabled' : '';
  }

  async submit() {
    this.router.toggleshowwait( true );

    this.deliveryaddress.addressLine1 = ( this.street + ' ' + this.housenumber + ' ' + this.housenumbersuffix ).trim();
    this.deliveryaddress.addressLine2 = this.postalcode + '  ' + this.city;

    this.deliveryaddress.street = this.street;
    this.deliveryaddress.number = Number(this.housenumber);
    this.deliveryaddress.suffix = this.housenumbersuffix;
    this.deliveryaddress.postalCode = this.postalcode;
    this.deliveryaddress.city = this.city;

    this.photovalidation      = this.list.getValue( this.router.persondata, 'photoValidation', true, true ) == 'True';
    this.photoapproved        = this.list.getValue( this.router.persondata, 'photoApproved', true, true )   == 'True';
    

    if ( await this.deliveryaddress.saveNewAddress() ) {
      if ( !this.list.emptyList ( this.environment.getEnvValue('cardprices') ) && this.router.amount != '0000' && !(this.router.persondata['cardStatus'] == 0)) {
        if( this.photovalidation && this.list.getValue( this.router.persondata, 'cardholderProfile', true ).toLowerCase() == 'student' ) {
          if(this.photoapproved) {
            this.router.goTo("payment");
          } else {
            this.savingDone = true;
            this.message.setSuccessMessage ( 'requestcard.awaitphotoapproval');
          }
        } else {
          if ( await this.card.create() ) {
            this.message.setSuccessMessage ( 'payment.success');
          } else {
            this.message.setErrorMessage( 'payment.fail' );
          }
        }
      } else {
        this.savingDone = true;
        this.message.setSuccessMessage ( 'requestcard.awaitphotoapprovalfirstphoto');
      }
    } else {
      this.message.setErrorMessage('deliveryaddress.failure');
    }

    this.router.toggleshowwait( false );
  }

  back() {
    this.postalcode        = '';
    this.housenumber       = '';
    this.housenumbersuffix = '';
    this.street            = '';
    this.city              = '';

    this.change            = false;
    this.addressfound      = false;
    this.saved             = false;
    this.searching         = false;
    this.message.error     = false;
  }

  cancel() {
    this.router.goHome();
  }

  ngOnDestroy() {
    return;
  }

}
