import { Component, OnInit, OnDestroy } from '@angular/core';
import { IconsService }  from '@omnipas/services/icons.service';
import { RouterService } from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-photorequirements',
  templateUrl: './photorequirements.component.html'
})
export class PhotoRequirementsComponent implements OnInit, OnDestroy {

  params;

  constructor ( public icons:   IconsService
              , public router:  RouterService
              ) {
  }

  ngOnInit() {
  }

  hidePhotoRequirements() {
    this.router.showphotorequirements = false;
  }

  showDisclaimer() {
  }

  showTerms() {
  }

  ngOnDestroy() {
    return;
  }

}
