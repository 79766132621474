import { Component, OnInit, OnDestroy } from '@angular/core';
import { EnvironmentService } from '@omnipas/services/environment.service';
import { LoadingService }     from '@omnipas/services/loading.service';
import { MessageService }     from '@omnipas/services/message.service';
import { PageHeaderService }  from '@omnipas/services/pageheader.service';
import { PhotoService }       from '@omnipas/services/photo.service';
import { RouterService }      from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-logout',
  templateUrl: './logout.component.html'
})
export class LogoutComponent implements OnInit, OnDestroy {
  initialized: boolean = false;
  removed:     boolean = false;

  constructor ( public environment: EnvironmentService
              , public loading:     LoadingService
              , public message:     MessageService
              , public pageheader:  PageHeaderService
              , public photo:       PhotoService
              , public router:      RouterService
              ) {
  }

  ngOnInit() {
    this.initialize();
  }

  async initialize() {
    this.router.toggleshowwait( true );
    this.initialized = false;

    if ( this.environment.getEnvValue('logintype') == 'surf' ) {
      this.pageheader.setPageHeader( 'pagetitle.logout2', 'pagetext.logout2' );
      this.router.toggleshowwait( false );
    } else {
      this.pageheader.setPageHeader( 'pagetitle.logout1', 'pagetext.logout1' );
      this.router.toggleshowwait( false );
    }

    this.router.persondata = {};
    this.photo.photo       = '';
    this.photo.photoResult = '';
    this.initialized = true;
  }

  async login() {
    this.router.toggleshowwait( true );
    this.router.toggleshowwait( false );
    this.router.goToLogin();
  }

  ngOnDestroy() {
  }
}
