import { Injectable, Inject }                        from '@angular/core';
import { Router, ActivatedRoute }                    from '@angular/router';
import { Location, PathLocationStrategy }            from '@angular/common';
import { AuthConfig, OAuthService, OAuthErrorEvent } from 'angular-oauth2-oidc';
import { DataService }          from '@omnilib/services/data.service';
import { EnvironmentService }   from '@omnipas/services/environment.service';
import { TransParamsInterface } from '@omnipas/interfaces/transparams.interface';

@Injectable({
  providedIn: 'root'
})
export class RouterService {

  sessioninfo: {};

  transparams: TransParamsInterface = { product            : 'OmniPAS'
                                      , customer_fullname  : ''
                                      , customer_shortname : ''
                                      , url                : ''
                                      , card               : 'kaart'
                                      , response           : ''
                                      , firstname          : ''
                                      , fullname           : ''
                                      , amount             : ''
                                      };

  amount:                string  = '0000';
  initialized:           boolean = false;
  loading:               boolean = false;
  routerList:            any[];
  back:                  boolean = false;
  showdigitalcard:       boolean = false;
  showabout:             boolean = false;
  showmoreinfo:          boolean = false;
  showpermissionsinfo:   boolean = false;
  showphotorequirements: boolean = false;
  toosmall:              boolean = false;
  paying:                boolean = false;
  waittext:              string  = '';
  showwait:              boolean = false;
  timeleft:              number  = 500;
  interval;
  maintainance:          boolean = false;
  firstPhoto:            boolean = false;

  mainwidth:             number  = 0;
  mainheight:            number  = 0;

  persondata = {};
  freefields = {};
  cardhist   = {};

  loggingin:             boolean = false;
  loggedin:              boolean = false;
  digitalcardinnewtab:   boolean = false;

  constructor( public router:               Router
             , public route:                ActivatedRoute
             , public data:                 DataService
             , public environment:          EnvironmentService
             , public oauthService:         OAuthService
             , public location:             Location
             , public pathLocationStrategy: PathLocationStrategy
             ) {
  }

  initialize() {
    if ( !this.initialized && !this.paying ) {
      this.routerList = [];
      this.initialized = true;
    }
  }

  requestPassword(){
    this.router.navigate(['/requestpassword']);
  }

  async goTo($link, clearToWelcome=false, clearAll=false ) {

    if ( $link == 'digitalcard' && window.innerWidth > window.innerHeight && window.innerWidth > 900 ) {
      this.digitalcardinnewtab = true;

      console.log ( location.origin );

      const url = this.router.serializeUrl(this.router.createUrlTree(['digitalcard']));

      window.open( location.origin + this.pathLocationStrategy.getBaseHref() + url, '_blank');
    } else {
      await this.toggleshowwait(true);

      this.digitalcardinnewtab = false;

      if ( $link == 'welcome' || $link == 'error' || $link == 'logincheck' ) {
        this.initialized = false;
        this.initialize();
      } else if ( clearToWelcome ) {
        let index: number  = 0;
        let found: boolean = false;

        for ( let i = 0; i < this.routerList.length; i++ ) {
          if ( this.routerList[i] == 'welcome' ) {
            found = true;
            index = i;
            break;
          }
        }

        if ( found ) {
          this.routerList.splice(index+1);
        }
      }

      this.back = clearToWelcome;

      if ( $link != 'digitalcard' && $link != 'surflogin' && $link != 'adfslogin' && $link != 'saml' ) {
        this.routerList.push($link);
      }

      this.paying = $link == 'payment';
      this.router.navigate(['/'+$link]);
    }
  }

  goHome() {
    this.back              = true;
    this.initialized       = false;
    this.paying            = false;
    this.initialize();
    this.showdigitalcard   = false;
    this.router.navigate(['/welcome']);
  }

  goToLogin() {
    this.toggleshowwait(true);

    this.back        = true;
    this.initialized = false;
    this.paying      = false;
    this.initialize();

    this.router.navigate(['/login']);
  }

  goBack() {
    this.toggleshowwait(true);

    this.back = true;
    this.routerList.splice(this.routerList.length-1, 1);
    this.paying = this.routerList[this.routerList.length-1] == 'payment';

    this.router.navigate(['/'+this.routerList[this.routerList.length-1]])
  }

  backTo($link) {
    this.toggleshowwait(true);

    this.back          = true;
    let index: number  = 0;
    let found: boolean = false;

    for ( let i = 0; i < this.routerList.length; i++ ) {
      if ( this.routerList[i] == $link ) {
        found = true;
        index = i;
        break;
      }
    }

    if ( found ) {
      this.routerList.splice(index+1);
    }

    this.paying = $link == 'payment';

    this.router.navigate(['/'+$link]);
  }

  logout() {
    this.toggleshowwait(true);
    this.routerList = [];
    this.data.clearjwt();
    this.router.navigate(['/logout']);

    this.toggleshowwait(false);
  }

  toggleshowwait( bool = false, text = 'loading.wait' ) {
    if ( bool ) {
      this.waittext = text;
    }
    this.showwait = bool;
  }

  starttimer() {
    this.timeleft = this.environment.getEnvValue('timeout') ?? 0;

    if ( this.timeleft > 0 ) {
      this.interval = setInterval(() => {
        if(this.timeleft > 0) {
          this.timeleft--;
        } else {
          this.toggleshowwait(true);
          this.logout();
        }
      },1000)
    }
  }
}
