<div class="w3-dropdown-hover w3-theme-l3 languagetoggle" title="{{ 'languagetoggle.title' | translate: router.transparams }}">
  <button class="w3-button w3-hover-theme w3-large">
    <span class="uppercase">{{ language.currentLanguage }}</span>
  </button>
  <div *ngIf="!list.emptyList(environment.getEnvValue('languages'))" class="w3-dropdown-content w3-theme-l5 w3-border-theme w3-card w3-round">
    <button
      *ngFor  ="let lang of environment.getEnvValue('languages')"
      class   ="w3-button w3-hover-theme w3-large w3-round"
      (click) ="language.changeLanguage(list.getValue(lang,'LanguageCode',true), list.getValue(lang,'Card', true))"
      title   ="{{ list.getValue(lang,'LanguageName',true) }}"
      [class] ="checkCurrentLanguage(list.getValue(lang,'LanguageCode',true))"
    >
      <span class="uppercase">{{ list.getValue(lang,'LanguageCode',true) }}</span>
    </button>
  </div>
</div>
