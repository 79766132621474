import { Component, OnInit, OnDestroy }   from '@angular/core';
import { ListService }         from '@omnilib/services/list.service';
import { LoadingService }      from '@omnipas/services/loading.service';
import { MessageService }      from '@omnipas/services/message.service';
import { PageHeaderService }   from '@omnipas/services/pageheader.service';
import { RouterService }       from '@omnipas/services/router.service';
import { VehicleDataService }  from '@omnipas/services/vehicledata.service';

@Component({
  selector: 'omnipas-chargingcard',
  templateUrl: './chargingcard.component.html'
})
export class ChargingCardComponent implements OnInit, OnDestroy {

  initialized: boolean = false;

  chargingcard: string = '';

  constructor ( public list:         ListService
              , public loading:      LoadingService
              , public message:      MessageService
              , public pageheader:   PageHeaderService
              , public router:       RouterService
              , public vehicledata:  VehicleDataService
              ) {
  }

  async ngOnInit() {

    this.router.toggleshowwait( true );
    this.initialized = false;

    if ( this.list.emptyList( this.router.persondata ) ) {
      this.router.logout();
    } else {
      this.vehicledata.get();
      this.pageheader.setPageHeader ( 'pagetitle.chargingcard', 'pagetext.chargingcard' );
    }

    this.initialized = true;
    this.router.toggleshowwait( false );
  }

  async submit() {
    this.router.toggleshowwait(true);

    if ( await this.vehicledata.update() ) {
      this.message.setSuccessMessage ( 'chargingcard.result.success', false, true );
    } else {
      this.message.setErrorMessage ( 'chargingcardnumber.result.failure', false, true );
    }

    this.router.toggleshowwait(false);
  }

  cancel() {
    this.router.backTo('welcome');
  }

  logout() {
    this.router.logout();
  }

  ngOnDestroy() {
    return;
  }

}
