import { Component, OnInit, OnDestroy }     from '@angular/core';
import { RouterService } from '@omnipas/services/router.service';

@Component({
  selector: 'omnipas-maintainance',
  templateUrl: './maintainance.component.html'
})
export class MaintainanceComponent implements OnInit, OnDestroy {

  constructor ( public router: RouterService
              ) {
  }

  ngOnInit() {
    this.router.toggleshowwait( false );
  }

  hideMaintainance() {
    this.router.maintainance = false;
  }

  ngOnDestroy() {
    return;
  }
}
