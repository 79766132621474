<div *ngIf="initialized" class="w3-container w3-margin-top" [class]="router.back ? 'w3-animate-left' : 'w3-animate-right'">
  <omnipas-pageheader></omnipas-pageheader>
  <div *ngIf="!message.error && !savingDone" #deliveryAddress id="deliveryAddress" class="w3-margin">
    <label for="street">{{ 'labels.street' | translate: router.transparams }}</label>
    <input
      id              = "street"
      name            = "street"
      class           = "w3-input w3-border w3-input-theme w3-round"
      type            = "text"
      placeholder     = ""
      pattern         = ""
      autocomplete    = "off"
      [(ngModel)]     = "street"
      required        = "required"
      tabindex        = "1"
    >
    <label for="housenumber">{{ 'labels.housenumber' | translate: router.transparams }}</label>
    <input
      id              = "housenumber"
      name            = "housenumber"
      class           = "w3-input w3-border w3-input-theme w3-round"
      type            = "text"
      placeholder     = ""
      pattern         = "[1-9][0-9]{4}"
      autocomplete    = "off"
      [(ngModel)]     = "housenumber"
      required        = "required"
      tabindex        = "2"
    >
    <label for="housenumbersuffix">{{ 'labels.housenumbersuffix' | translate: router.transparams }}</label>
    <input
      id            = "housenumbersuffix"
      name          = "housenumbersuffix"
      class         = "w3-input w3-border w3-input-theme w3-round"
      type          = "text"
      placeholder   = ""
      pattern       = ""
      autocomplete  = "off"
      [(ngModel)]   = "housenumbersuffix"
      tabindex      = "3"
    >
    <br/>
    <label for="postalcode">{{ 'labels.postalcode' | translate: router.transparams }}</label>
    <input
      id              = "postalcode"
      name            = "postalcode"
      class           = "w3-input w3-border w3-input-theme w3-round"
      type            = "text"
      placeholder     = ""
      pattern         = "[1-9][0-9]{3} ?(?!sa|sd|ss|SA|SD|SS)[A-Za-z]{2}"
      autocomplete    = "off"
      [(ngModel)]     = "postalcode"
      required        = "required"
      tabindex        = "4"
    >
    <label for="woonplaats">{{ 'labels.city' | translate: router.transparams }}</label>
    <input
      id              = "city"
      name            = "city"
      class           = "w3-input w3-border w3-input-theme w3-round"
      type            = "text"
      placeholder     = ""
      pattern         = ""
      autocomplete    = "off"
      [(ngModel)]     = "city"
      required        = "required"
      tabindex        = "5"
    >
    <br/>
    <input
      type        = "submit"
      class       = "w3-button w3-border w3-button-theme w3-mobile w3-margin-right pageButton w3-round"
      name        = "submit"
      value       = "{{ 'buttons.save' | translate: router.transparams }}"
      title       = "{{ 'buttons.save' | translate: router.transparams }}"
      (click)     = "submit()"
      [disabled]  = "enableSubmit()"
    >
  </div>
  <div *ngIf="message.error" class="w3-margin">
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "back"
      value       = "{{ 'buttons.back' | translate: router.transparams }}"
      title       = "{{ 'buttons.back' | translate: router.transparams }}"
      (click)     = "back()"
    >
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: router.transparams }}"
      title       = "{{ 'buttons.logout' | translate: router.transparams }}"
      (click)     = "cancel()"
    >
  </div>
  <div *ngIf="savingDone" class="w3-margin">
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "back"
      value       = "{{ 'buttons.back' | translate: router.transparams }}"
      title       = "{{ 'buttons.back' | translate: router.transparams }}"
      (click)     = "cancel()"
    >
    <input
      type        = "button"
      class       = "w3-button w3-border w3-button-theme w3-mobile pageButton w3-round"
      name        = "logout"
      value       = "{{ 'buttons.logout' | translate: router.transparams }}"
      title       = "{{ 'buttons.logout' | translate: router.transparams }}"
      (click)     = "logout()"
    >
  </div>
</div>
