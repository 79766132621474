import { Component, OnInit, OnChanges, Input, OnDestroy, HostListener, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { BarcodeService }    from '@omnilib/services/barcode.service';

@Component({
  selector: 'omnicard-barcode',
  templateUrl: './barcode.component.html'
})

export class BarcodeComponent implements OnInit, OnChanges, OnDestroy {

  bccode;
  bcid;

  @Input() code : string = '';
  @Input() displayvalue : boolean = false;

  @ViewChild('barcodediv') public barcodediv: ElementRef;

  constructor ( public barcode:  BarcodeService
              , public renderer: Renderer2
              ) {
  }

  async ngOnInit() {
    await this.initialize();
  }


  async ngOnChanges() {
    //await this.initialize();
  }

  async initialize() {

    this.bcid   = 'barcode_'+this.code;
    this.bccode = await this.barcode.getcode128(this.code);

    let x       = 0;
    let x_total = 0;
    let svg     = document.createElementNS('http://www.w3.org/2000/svg', 'svg');
    let g       = document.createElementNS('http://www.w3.org/2000/svg', 'g');

    for ( let i in this.bccode ) {
      x_total += +this.bccode[i];
    }

    for ( let i in this.bccode ) {
      if ( +i == 0 || +i%2 == 0 ) {
        let rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');

        rect.setAttributeNS(null, 'x', ( ( x/x_total ) * 100 ).toString() + '%');
        //rect.setAttributeNS(null, 'x', x.toString());
        rect.setAttributeNS(null, 'y', '0');

        rect.setAttributeNS(null, 'width', ( ( this.bccode[i]/x_total ) * 100 ).toString() + '%' );
        //rect.setAttributeNS(null, 'width', this.bccode[i].toString());
        rect.setAttributeNS(null, 'height', '100%');
        rect.setAttributeNS(null, 'fill', '#000')
        this.renderer.appendChild(g, rect);
      }

      x += +this.bccode[i];
    }

    this.renderer.appendChild(svg, g);
    this.renderer.appendChild(this.barcodediv.nativeElement, svg);
  }

  @HostListener('unloaded')
  ngOnDestroy() {
  }

}
