import { Injectable } from '@angular/core';
import { icon } from '@fortawesome/fontawesome-svg-core';
import {
  faAt,
  faBars,
  faChevronDown,
  faChevronUp,
  faEye,
  faEyeSlash,
  faHome,
  faInfo,
  faPhone,
  faRedo,
  faRightFromBracket,
  faSpinner,
  faTimes,
  faUndo
} from '@fortawesome/free-solid-svg-icons';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  faat          = faAt;
  fabars        = faBars;
  fachevrondown = faChevronDown;
  fachevronup   = faChevronUp;
  faeye         = faEye;
  faeyeslash    = faEyeSlash;
  fahome        = faHome;
  fainfo        = faInfo;
  falogout      = faRightFromBracket;
  faphone       = faPhone;
  faredo        = faRedo;
  faspinner     = faSpinner;
  fatimes       = faTimes;
  faundo        = faUndo;
}
