<div *ngIf="router.transparams.customer_shortname != ''" id="permissionsinfocontent" class="w3-modal-content w3-display-content w3-animate-zoom w3-theme-l4 w3-card-4">
  <header class="w3-theme-l3 w3-display-container w3-border-bottom w3-border-theme">
    <fa-icon id="icon-close" [icon]="icons.fatimes" class="w3-display-topright w3-hover-shadow close" (click)="hide()" title="{{ 'buttons.close' | translate: router.transparams }}"></fa-icon>
    <h2 class="w3-display-left w3-margin-left fullheight">{{'pagetitle.permissionsinfo' | translate: router.transparams | titlecase }}</h2>
  </header>
  <div class="w3-container w3-margin-top w3-theme-l4">
    <p *ngIf="list.getValue( this.router.persondata, 'cardholderProfile', true ) == 'student'"  innerHTML="{{ 'permissionsinfo.student' | translate: router.transparams }}"></p>
    <p *ngIf="list.getValue( this.router.persondata, 'cardholderProfile', true ) != 'student'"  innerHTML="{{ 'permissionsinfo.employee' | translate: router.transparams }}"></p>
    <br/>
  </div>
  <footer class="w3-theme-l3 w3-border-top w3-border-theme">
  </footer>
</div>
