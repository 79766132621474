import { Injectable }          from '@angular/core';
import { DataService }         from '@omnilib/services/data.service';
import { EnvironmentService }  from '@omnipas/services/environment.service';
import { LanguageService }     from '@omnipas/services/language.service';
import { ListService }         from '@omnilib/services/list.service';
import { LoadingService }      from '@omnipas/services/loading.service';
import { LocalStorageService } from '@omnilib/services/localstorage.service';
import { RouterService }       from '@omnipas/services/router.service';

import CryptoES from 'crypto-es';
import { format, toDate  }  from 'date-fns';

@Injectable({
  providedIn: 'root'
})
export class PaymentService  {

  ingenico          = {};
  url:       string = '';

  constructor( public data:         DataService
             , public environment:  EnvironmentService
             , public language:     LanguageService
             , public list:         ListService
             , public loading:      LoadingService
             , public localstorage: LocalStorageService
             , public router:       RouterService
             ) {
  }

  async shasign( shastring ) {
    return CryptoES.SHA1( shastring ).toString().toUpperCase();
  }

  async getSign( $fields, $sign ) {
    let shastring: string = '';

    Object.keys($fields).sort().forEach(function(key) {
      if ( $fields[key] != '' ) {
        shastring += `${key}=${$fields[key]}${$sign}`;
      }
    });

    return await this.shasign( shastring );
  }

  getLanguagueCode( $lang ){
    switch($lang) {
      case 'eng' :
      case 'en'  :
        return 'en_US';
        break;
      case 'nl'  :
      case 'nld' :
      default    :
        return 'nl_NL';
        break;
    }
  }

  getAddress() {
    let address = '';
    address += this.list.getValue( this.router.persondata, 'street', true ) + ' ' + this.list.getValue( this.router.persondata, 'number', true ) + ' ' + this.list.getValue( this.router.persondata, 'suffix', true );
    return address.trim();
  }

  async initialize() {
    let shasign: string  = '';

    let ingenico = this.environment.getEnvValue( 'ingenico' );

    this.url      = this.list.getValue( ingenico, 'url', true );

    this.ingenico = {
      'PSPID'          : this.list.getValue( ingenico, 'pspid', true ),
      'ORDERID'        : this.createOrderID(),
      'AMOUNT'         : this.router.amount,
      'CURRENCY'       : this.list.getValue( ingenico, 'currency', true ),
      'LANGUAGE'       : this.getLanguagueCode( this.language.currentLanguage ),
      'CN'             : this.router.transparams.fullname,
      'EMAIL'          : this.list.getValue( this.router.persondata, 'email', true ),
      'OWNERZIP'       : this.list.getValue( this.router.persondata, 'postalCode', true ),
      'OWNERADDRESS'   : this.getAddress(),
      'OWNERCTY'       : this.list.getValue( this.router.persondata, 'city', true ),
      'OWNERTOWN'      : this.list.getValue( this.router.persondata, 'city', true ),
      'COM'            : "",
      'TITLE'          : this.language.getTranslation('payment.requestNewCard'),
      'BGCOLOR'        : this.list.getValue( ingenico, 'bgcolor', true ),
      'TXTCOLOR'       : this.list.getValue( ingenico, 'txtcolor', true ),
      'TBLBGCOLOR'     : this.list.getValue( ingenico, 'tblbgcolor', true ),
      'TBLTXTCOLOR'    : this.list.getValue( ingenico, 'tbltxtcolor', true ),
      'BUTTONBGCOLOR'  : this.list.getValue( ingenico, 'buttonbgcolor', true ),
      'BUTTONTXTCOLOR' : this.list.getValue( ingenico, 'buttontxtcolor', true ),
      'FONTTYPE'       : this.list.getValue( ingenico, 'fonttype', true ),
      'PM'             : this.list.getValue( ingenico, 'pm', true ),
      'PARAMPLUS'      : this.jwtparam(),
      'HOMEURL'        : this.list.getValue( ingenico, 'homeurl', true ),
      'ACCEPTURL'      : this.list.getValue( ingenico, 'accepturl', true ),
      'DECLINEURL'     : this.list.getValue( ingenico, 'declineurl', true ),
      'EXCEPTIONURL'   : this.list.getValue( ingenico, 'exceptionurl', true ),
      'CANCELURL'      : this.list.getValue( ingenico, 'cancelurl', true ),
      'SHASIGN'        : ''
    };

    shasign = await this.getSign( this.ingenico, this.list.getValue( ingenico, 'sha_in_key', true ) );

    this.ingenico['SHASIGN'] = shasign;
  }

  jwtparam() {
    let jwtjson = `JWT=<<jwtstring>>`;
    return jwtjson.replace( "<<jwtstring>>", this.data.getjwt() );
  }

  createOrderID() {
    let orderid = this.list.getValue( this.environment.getEnvValue('ingenico'), 'orderid', true );

    return ( orderid == '' ? 'CARDREQUEST_' : orderid )
         + format(toDate(new Date()),'ddMMyyyyHHmmss')
         + '&_&'
         + this.list.getValue( this.router.persondata, 'cardholderID', true )
    ;
  }

  async checkSignOut( $fields, $sign ) {
    let shasign:   string = '';
    let shastring: string = '';

    let cleanfields = {};

    Object.keys($fields).forEach(function(key) {
      if ( key != 'JWT' ) {
        cleanfields[decodeURIComponent(key).toUpperCase()] = decodeURIComponent($fields[key]);
      }
    });

    Object.keys(cleanfields).sort().forEach(function(key) {
      if ( key == 'SHASIGN' ) {
        shasign = cleanfields[key];
      } else if ( cleanfields[key] != '' ) {
        shastring += `${key.toUpperCase()}=${cleanfields[key]}${$sign}`;
      }
    });

    return shasign == await this.shasign(shastring);
  }

  async updatecardholdernote ( note ) {
    if ( note != '' && !this.list.emptyList( this.router.persondata ) ) {

      let oldnote = this.list.getValue( this.router.persondata, 'note', true );

      let newnote = ( oldnote != '' ? oldnote + '\n\n' : '' ) + format(toDate(new Date()),'yyyy-MM-dd HH:mm:ss') + ' ' + note;

      let params = { 'Note' : newnote
                   , 'StatusId' : this.list.getValue( this.router.persondata, 'cardStatus', true )
                   };
    }
  }

}
